import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

export default function SajCard() {
  return (
    <div className="mx-1 w-2/3 flex-none pr-4 md:w-1/3 md:pb-4">
      <div className="rounded-t-md drop-shadow-md hover:drop-shadow-lg">
        <Link
          to="/sazhevyie-filtry"
          aria-label="Открыть каталог сажевых фильтров"
        >
          <div className="group !aspect-w-16 !aspect-h-9 cursor-pointer overflow-hidden">
            <StaticImage
              src="../../../assets/images/price/saj-category.png"
              alt="Сажевый фильтр"
              className="rounded-t-lg bg-white object-cover duration-500 ease-in-out group-hover:rotate-6 group-hover:scale-125"
            />
          </div>
        </Link>
        <div className="rounded-b-md border-t border-gray-300 bg-white px-4 py-2">
          <div className="space-y-1">
            <h4 className="mb-2 text-lg font-medium text-gray-900">
              Сажевые фильтры
            </h4>
          </div>
          <div className="">
            <p className="text-sm text-gray-600 sm:text-base">
              Импорт до <span className="font-semibold">12 000</span> руб/кг
            </p>
            <p className="text-sm text-gray-600 sm:text-base">
              Отечеств. до <span className="font-semibold">4 000</span> руб/кг
            </p>
            <Link
              to="/sazhevyie-filtry"
              className="mt-2 inline-flex items-center text-sm font-medium text-corp-orange hover:text-orange-600"
            >
              Открыть каталог
              <ChevronDoubleRightIcon className="ml-1 h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
