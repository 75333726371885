import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactSection from "../components/Home/ContactSection"

import CeramicCard from "../components/Home/PriceSection/CeramicCard"
import MetalCard from "../components/Home/PriceSection/MetalCard"
import SajCard from "../components/Home/PriceSection/SajCard"

const CatalogPage = () => (
  <Layout>
    {
      // eslint-disable-next-line
      <SEO
        title="Пункты приема катализаторов и контакты"
        description="Все пункты приема катализаторов и сажевых фильтров по всей Росиии и СНГ"
      />
    }
    <section className="bg-gray-50 py-6 md:py-24" id="price">
      <div className="mx-auto max-w-full md:max-w-7xl">
        <h1 className="pt-24 text-center text-4xl font-extrabold md:text-5xl">
          Цены на б/у катализаторы
        </h1>
        <h2 className="px-6 pt-2 text-center text-xl font-light md:px-12  md:text-2xl">
          Принимаем любые автомобильные катализаторы по самым высоким ценам в РФ
        </h2>

        <div className="mx-auto flex flex-col justify-center space-x-0 pt-6 md:flex-row md:space-x-6 md:pt-12">
          <CeramicCard />
          <MetalCard />
          <SajCard />
        </div>
      </div>
    </section>

    <ContactSection />
  </Layout>
)

export default CatalogPage
